import React from 'react';
import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react';

const Tutorial = () => (
  <IonCard>
    <IonCardHeader>Tutorial: How to Use the Video Clip Generator</IonCardHeader>
    <IonCardContent>
      <ul>
        <li>📂 <strong>Upload a Video:</strong> Select a video file to work with.</li>
        <li>⏱️ <strong>Set Clip Parameters:</strong> Define the number of clips, min, and max duration.</li>
        <li>⚙️ <strong>Generate Clips:</strong> Click the "Generate Clips" button to create your clips.</li>
        <li>🗑️ <strong>Discard Clips:</strong> Remove unwanted clips from the list.</li>
        <li>📥 <strong>Download Clips:</strong> Download individual clips or all clips as a ZIP file.</li>
      </ul>
    </IonCardContent>
  </IonCard>
);

export default Tutorial;
