import React, { useState, useEffect } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { IonButton } from '@ionic/react';
import ClipDisplay from './ClipDisplay';
import InputOptions from './InputOptions'; // Import InputOptions

const ffmpeg = new FFmpeg({ log: true });

const ClipMaker = ({ videoFile, minLength, maxLength, videoDuration, numberOfClips, isProcessing, setIsProcessing }) => {
  const [clipURLs, setClipURLs] = useState([]);
  const [isFFmpegLoaded, setIsFFmpegLoaded] = useState(false);
  const [currentClipIndex, setCurrentClipIndex] = useState(0);

  useEffect(() => {
    const loadFFmpeg = async () => {
      if (!isFFmpegLoaded) {
        await ffmpeg.load();
        setIsFFmpegLoaded(true);
      }
    };
    loadFFmpeg();
  }, [isFFmpegLoaded]);

  useEffect(() => {
    if (isProcessing && currentClipIndex < numberOfClips) {
      generateClip(currentClipIndex);
    }
  }, [currentClipIndex, isProcessing]);

  const generateClip = async (index) => {
    console.log(`Generating clip #${index + 1}`);
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        await ffmpeg.writeFile('input.mp4', new Uint8Array(arrayBuffer));

        const duration = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
        const randomStart = Math.random() * (videoDuration - duration);
        const filename = `clip-${Date.now()}.mp4`;

        await ffmpeg.exec([
          '-i', 'input.mp4',
          '-ss', randomStart.toFixed(2),
          '-t', duration.toString(),
          '-c:v', 'copy',
          '-c:a', 'copy',
          filename,
        ]);

        const data = await ffmpeg.readFile(filename);
        const blob = new Blob([data.buffer], { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);

        setClipURLs((prev) =>
          prev.map((clip, i) =>
            i === index ? { url, isProcessing: false } : clip
          )
        );

        setCurrentClipIndex((prevIndex) => prevIndex + 1);
      };

      reader.readAsArrayBuffer(videoFile);
    } catch (error) {
      console.error('Error generating clip:', error);
      setIsProcessing(false);
    }
  };

  const startGeneratingClips = () => {
    if (!isFFmpegLoaded || isProcessing) return;

    console.log(`Generating ${numberOfClips} clips...`);
    setIsProcessing(true);
    setCurrentClipIndex(0);

    const placeholders = Array.from({ length: numberOfClips }, () => ({
      url: '',
      isProcessing: true,
    }));
    setClipURLs(placeholders);
  };

  return (
    <div style={{ marginTop: '1rem' }}>

      {!isProcessing && (
        <IonButton onClick={startGeneratingClips} disabled={!isFFmpegLoaded}>
          Make {numberOfClips} Clips
        </IonButton>
      )}

      <div style={{ marginTop: '1rem', display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
        {clipURLs.map((clip, index) => (
          <ClipDisplay
            key={index}
            clipURL={clip.url}
            isProcessing={clip.isProcessing}
            onDelete={() => setClipURLs((prev) => prev.filter((_, i) => i !== index))}
          />
        ))}
      </div>
    </div>
  );
};

export default ClipMaker;
