import React from 'react';
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput } from '@ionic/react';

const InputOptions = ({ minLength, maxLength, onChange, numberOfClips, setNumberOfClips }) => (
  <IonGrid>
    <IonRow>
      <IonCol size="4">
        <IonItem>
          <IonLabel position="stacked">Min Length (seconds)</IonLabel>
          <IonInput
            type="number"
            value={minLength}
            onIonChange={(e) => onChange('minLength', Number(e.detail.value))}
            min={1}
            inputmode="numeric"
          />
        </IonItem>
      </IonCol>

      <IonCol size="4">
        <IonItem>
          <IonLabel position="stacked">Max Length (seconds)</IonLabel>
          <IonInput
            type="number"
            value={maxLength}
            onIonChange={(e) => onChange('maxLength', Number(e.detail.value))}
            min={1}
            inputmode="numeric"
          />
        </IonItem>
      </IonCol>

      <IonCol size="4">
        <IonItem>
          <IonLabel position="stacked">Number of Clips</IonLabel>
          <IonInput
            type="number"
            value={numberOfClips}
            onIonChange={(e) => setNumberOfClips(Number(e.detail.value))}
            min={1}
            inputmode="numeric"
          />
        </IonItem>
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default InputOptions;
