import React, { useState } from 'react';
import { IonApp, IonContent, IonHeader, IonToolbar, IonTitle, IonImg } from '@ionic/react';
import Tutorial from './components/Tutorial';
import InputVideo from './components/InputVideo';
import InputOptions from './components/InputOptions';
import ClipMaker from './components/ClipMaker';


import yankyLogo from './yanky.png';

const App = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [options, setOptions] = useState({ minLength: 6, maxLength: 8 });
  const [numberOfClips, setNumberOfClips] = useState(10);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleOptionChange = (key, value) => {
    setOptions((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <IonApp>
      <IonHeader>
        <IonToolbar color="primary">
        <IonImg src={yankyLogo} style={{ width: '150px', height: 'auto', marginRight: 'auto', marginLeft:'auto' }} />
        <IonTitle style={{textAlign: 'center'}}>Yanky: Clip It & Rip It 🎥</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        
        <InputVideo onVideoSelected={(file, duration) => {
          setVideoFile(file);
          setVideoDuration(duration);
        }} />
        {videoFile && !isProcessing && (
          <InputOptions
            minLength={options.minLength}
            maxLength={options.maxLength}
            onChange={handleOptionChange}
            numberOfClips={numberOfClips}
            setNumberOfClips={setNumberOfClips}
          />
        )}
        {videoFile && (
          <ClipMaker
            videoFile={videoFile}
            videoDuration={videoDuration}
            minLength={options.minLength}
            maxLength={options.maxLength}
            numberOfClips={numberOfClips}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
          />
        )}
      </IonContent>
    </IonApp>
  );
};

export default App;
