import React, { useState, useRef } from 'react';
import { IonItem, IonLabel, IonButton, IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { cloudUploadOutline } from 'ionicons/icons';

const InputVideo = ({ onVideoSelected }) => {
  const [videoURL, setVideoURL] = useState(null); // State to store video preview URL
  const inputRef = useRef(null); // Reference for hidden file input

  const handleVideoChange = (file) => {
    if (file) {
      const url = URL.createObjectURL(file); // Create blob URL for preview
      setVideoURL(url); // Store URL for rendering the video
      const videoElement = document.createElement('video');

      // Load metadata to get video duration
      videoElement.src = url;
      videoElement.onloadedmetadata = () => {
        onVideoSelected(file, videoElement.duration); // Pass raw file and duration
      };
    }
  };

  const handleUploadClick = () => inputRef.current.click(); // Trigger file input click

  const onFileInputChange = (e) => handleVideoChange(e.target.files[0]); // Handle file input

  const onDrop = (e) => {
    e.preventDefault();
    handleVideoChange(e.dataTransfer.files[0]); // Handle dropped file
  };

  const onDragOver = (e) => e.preventDefault(); // Prevent default drag behavior

  return (
    <div>
    {!videoURL && <div
      onDrop={onDrop}
      onDragOver={onDragOver}
      style={{
        border: '2px dashed #ccc',
        borderRadius: '8px',
        padding: '1rem',
        textAlign: 'center',
        marginTop: '1rem',
      }}
      aria-label="Drop video file here or click to upload"
      tabIndex={0}
    >
      <input
        type="file"
        accept="video/*"
        ref={inputRef}
        onChange={onFileInputChange}
        style={{ display: 'none' }}
      />

      <IonButton expand="block" onClick={handleUploadClick} style={{ marginBottom: '1rem' }}>
        <IonIcon slot="start" icon={cloudUploadOutline} />
        Input Video
      </IonButton>

      <p>Or drag and drop a video here</p>

      
    </div>
    }

    {videoURL && (
        <IonCard
          style={{
            marginTop: '1rem',
            maxWidth: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxHeight: '300px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IonCardContent style={{ padding: '0' }}>
            <video
              src={videoURL}
              autoPlay
              muted
              loop
              controls
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </IonCardContent>
        </IonCard>
      )}


    </div>
    
  );
};

export default InputVideo;
